<template>
  <section>
    <two-panel
      :leftPanelWidth="'1050'"
      :rightPanelWidth="'337'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <!---------------------------- 분석환경 목록 ---------------------------->
        <table-list-component
          ref="tableListComponent"
          :tableHeight="'603'"
          :headers="headers"
          :items="tableItems"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :customSlotInfo="customSlotInfo"
          :reloadToggle="reloadToggle"
          :loading="loading"
          @rowClick="rowClick"
          @getDataFromApi="searchEnvmList"
        >
          <!---- 검색 옵션 ---->
          <template #searchOptions>
            <label class="sort-label"
              >· {{ $t('support.environment.search-condition1') }}</label
            >
            <input
              type="number"
              v-model="searchOpts.analsEnvrnId"
              class="sort-input-text"
              style="width:80px"
              maxlength="20"
              @keyup.enter="keyUpEnter"
              @keydown="inputNumberCheck"
            />
            <label class="sort-label">· </label>
            <select
              v-model="searchOpts.srchCondition"
              class="sort-input-select"
              style="width:90px"
            >
              <option :value="'srvcNm'">{{
                $t('support.environment.search-condition2')
              }}</option>
              <option :value="'srvcId'">{{
                $t('support.environment.search-condition3')
              }}</option>
            </select>
            <input
              type="text"
              v-model="searchOpts.srchData"
              class="sort-input-text ml-1"
              maxlength="20"
              style="width: 100px"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label"
              >· {{ $t('support.environment.search-condition4') }}
            </label>
            <select
              class="sort-input-select"
              style="width:120px"
              v-model="searchOpts.confmSttus"
            >
              <option value="">{{ $t('common.all') }}</option>
              <option
                v-for="(item, index) in confmSttusList"
                :key="index"
                :value="item.codeId"
              >
                {{ item.codeName }}
              </option>
            </select>
            <label class="sort-label"
              >· {{ $t('support.environment.search-condition5') }}</label
            >
            <input
              type="text"
              v-model="searchOpts.conectAdres"
              class="sort-input-text"
              maxlength="20"
              style="width: 100px"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
          </template>
          <!---- subHeaderItem ---->
          <template #subHeaderItem>
            <input
              type="button"
              :value="btnRegister"
              class="bt-line24 cr-orange"
              @click="openPopup('insert')"
            />
          </template>
          <!---- customSlot ---->
          <template v-slot:confmSttus_custom="{ item }">
            <td
              class="text-center"
              :style="{ color: getConfmSttusColor(item.confmSttus) }"
            >
              {{ getConfmSttusNm(item.confmSttus) }}
            </td>
          </template>
          <template v-slot:srvcNm_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.srvcNm">
              {{ item.srvcNm || '-' }}
            </td>
          </template>
        </table-list-component>
      </template>
      <!---- 상세 정보 detailInfo ---->
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
          <!-- custom slot-->
          <template v-slot:srvc_tab_custom>
            <td>
              {{
                tabItems[0].selectedItem.srvcNm.value
                  ? `${tabItems[0].selectedItem.srvcNm.value}(${tabItems[0].selectedItem.srvcId.value})`
                  : '-'
              }}
            </td>
          </template>
          <template v-slot:confmSttus_tab_custom="{ item }">
            <td :style="{ color: getConfmSttusColor(item.value) }">
              {{ getConfmSttusNm(item.value) || '-' }}
            </td>
          </template>
          <!-- bottom btn -->
          <template #detail_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdateStatus"
                  class="bt-line24 cr-gray"
                  @click="openPopup('confmSttus')"
                />
                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray ml-1"
                  @click="openPopup('update')"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray ml-1"
                  @click="deleteSubmit"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>

    <!---------------------------- 분석환경 팝업 ---------------------------->
    <div
      class="pop-drag"
      v-if="popup.show && (popup.type == 'insert' || popup.type == 'update')"
    >
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:500px">
        <div class="pop-head">
          <h2>{{ popup.title }}</h2>
          <button class="btn-x" @click="closePopup"></button>
        </div>
        <div class="pop-body" style="overflow:unset">
          <validation-observer ref="observer">
            <form enctype="multipart/form-data">
              <table class="table-style-default table-pop">
                <colgroup>
                  <col width="110px" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr v-if="popup.type == 'update'">
                    <th>{{ $t('support.environment.popUp.label1') }}</th>
                    <td>
                      <input
                        type="text"
                        class="input-24"
                        name="u_predictModlId"
                        style="width:200px"
                        readonly="readonly"
                        :v-model="tabItems[0].selectedItem.srvcId.value"
                        :value="
                          `${tabItems[0].selectedItem.srvcNm.value}(${tabItems[0].selectedItem.srvcId.value})`
                        "
                      />
                    </td>
                  </tr>
                  <tr v-else>
                    <th>
                      {{ $t('support.environment.popUp.label1')
                      }}<i class="star">*</i>
                    </th>
                    <td>
                      <select
                        v-model="popup.submitItem.srvcId"
                        class="input-24 w-100"
                      >
                        <option
                          v-for="(item, index) in srvcList"
                          :key="index"
                          :value="item.srvcId"
                        >
                          {{ `${item.srvcNm}(${item.srvcId}) ` }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {{ $t('support.environment.popUp.label2')
                      }}<i class="star">*</i>
                    </th>
                    <td>
                      <select
                        v-model="popup.submitItem.confmSttus"
                        class="input-24 w-100"
                      >
                        <option
                          v-for="(item, index) in confmSttusList"
                          :key="index"
                          :value="item.codeId"
                        >
                          {{ item.codeName }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ analStart }}</th>
                    <td>
                      <vc-date-picker
                        v-model="popup.submitItem.analsBegin"
                        mode="dateTime"
                        :min-date="popup.type == 'insert' ? new Date() : ''"
                        :max-date="
                          popup.type == 'insert'
                            ? popup.submitItem.analsEnd
                            : popup.submitItem.analsEnd &&
                              new Date(popup.submitItem.analsEnd) > new Date()
                            ? new Date(popup.submitItem.analsEnd)
                            : ''
                        "
                        :model-config="modelConfig"
                        :masks="{ inputDateTime: 'YYYY/MM/DD HH:mm:00' }"
                        color="orange"
                        popover-visibility="focus"
                      >
                        <template v-slot="{ togglePopover }">
                          <validate-form
                            :label="analStart"
                            v-model="popup.submitItem.analsBegin"
                            name="analsBegin"
                            :rules="{
                              min_value:
                                popup.type == 'insert' ? new Date() : '',
                              max_value:
                                popup.submitItem.analsEnd &&
                                new Date(popup.submitItem.analsEnd) > new Date()
                                  ? new Date(popup.submitItem.analsEnd)
                                  : '',
                            }"
                            inputClass="input-24 w-100"
                            placeholder="yyyy/mm/dd HH:MM:00"
                            maxlength="200"
                            @click="togglePopover()"
                            :readonly="true"
                          />
                        </template>
                      </vc-date-picker>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ analEnd }}</th>
                    <td>
                      <vc-date-picker
                        v-model="popup.submitItem.analsEnd"
                        mode="dateTime"
                        :min-date="
                          popup.submitItem.analsBegin
                            ? popup.submitItem.analsBegin
                            : popup.type == 'insert'
                            ? new Date()
                            : ''
                        "
                        :model-config="modelConfig"
                        color="orange"
                      >
                        <template v-slot="{ togglePopover }">
                          <validate-form
                            :label="analEnd"
                            v-model="popup.submitItem.analsEnd"
                            name="analsBegin"
                            :rules="{
                              min_value: popup.submitItem.analsBegin
                                ? new Date(popup.submitItem.analsBegin)
                                : popup.type == 'insert'
                                ? new Date()
                                : '',
                            }"
                            inputClass="input-24 w-100"
                            placeholder="yyyy/mm/dd HH:MM:00"
                            maxlength="200"
                            @click="togglePopover()"
                            :readonly="true"
                          />
                        </template>
                      </vc-date-picker>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ analTool }}</th>
                    <td>
                      <validate-form
                        :label="analTool"
                        v-model="popup.submitItem.analsUnt"
                        name="analsUnt"
                        :rules="{ max: 200 }"
                        inputClass="input-24 w-100"
                        maxlength="200"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ analData }}</th>
                    <td>
                      <validate-form
                        :label="analData"
                        v-model="popup.submitItem.analsData"
                        name="analsData"
                        :rules="{ max: 200 }"
                        inputClass="input-24 w-100"
                        maxlength="200"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ accessMethod }}</th>
                    <td>
                      <validate-form
                        :label="accessMethod"
                        v-model="popup.submitItem.conectMth"
                        name="conectMth"
                        :rules="{ max: 200 }"
                        inputClass="input-24 w-100"
                        maxlength="200"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ accessAddress }}</th>
                    <td>
                      <validate-form
                        :label="accessAddress"
                        v-model="popup.submitItem.conectAdres"
                        name="conectAdres"
                        :rules="{ max: 200 }"
                        inputClass="input-24 w-100"
                        maxlength="200"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </validation-observer>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="popup.type == 'insert' ? btnRegister : btnSubmit"
              class="bt-line30 cr-orange"
              @click="popup.type == 'insert' ? insertSubmit() : updateSubmit()"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="closePopup"
            />
          </p>
        </div>
      </div>
    </div>

    <!-- 서비스 상태 변경 팝업 -->
    <div v-if="popup.show && popup.type == 'confmSttus'">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:350px;">
        <div class="pop-head">
          <h2>{{ popup.title }}</h2>
          <button class="btn-x"></button>
          <button @click="closePopup"></button>
        </div>
        <div class="pop-body">
          <div class="pop-question">
            <select
              v-model="popup.submitItem.confmSttus"
              class="input-24 w-100"
            >
              <option
                v-for="(item, index) in confmSttusList"
                :key="index"
                :value="item.codeId"
              >
                {{ item.codeName }}
              </option>
            </select>
          </div>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="btnUpdateStatus"
              class="bt-line30 cr-orange"
              @click="updateConfmSttus"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="closePopup"
            />
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import ValidateForm from '@/components/ValidateForm.vue';

import CommonMixins from '@/mixins/CommonMixins';
import {
  getAnalEnvList,
  getAnalEnvDetail,
  insertAnalEnv,
  updateAnalEnv,
  deleteAnalEnv,
  updateAnalConfmSttus,
} from '@/api/supportApi';
import { getCommonCode, getSrvcList } from '@/api/commonApi';
import { COMMON_GROUP_CODE } from '@/store/properties';

export default {
  mixins: [CommonMixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    ValidateForm,
  },
  data() {
    return {
      // --- 테이블 --- //
      loading: false,
      // --- custom Slot --- //
      customSlotInfo: [
        { name: 'confmSttus', slotName: 'item.confmSttus' },
        { name: 'srvcNm', slotName: 'item.srvcNm' },
      ],
      // 검색 정보
      confmSttusList: [],
      searchOpts: {
        analsEnvrnId: '',
        srchCondition: 'srvcNm',
        srchData: '',
        confmSttus: '',
        conectAdres: '',
      },
      // row
      totalRecords: 0,
      totalPages: 0,
      tableItems: [],
      nowPerPage: 30,
      reloadToggle: true,
      // 우측 패널 테이블
      tabOverlay: false,
      tabItems: [],
      // -- 팝업 -- //
      srvcList: [],
      modelConfig: {
        type: 'string',
        mask: 'YYYY/MM/DD HH:mm:00', // Uses 'iso' if missing
      },
      popup: {
        type: '',
        show: false,
        submitItem: {
          srvcId: '',
          confmSttus: '',
          analsBegin: '',
          analsEnd: '',
          analsUnt: '',
          analsData: '',
          conectMth: '',
          conectAdres: '',
        },
        errorData: {},
      },
    };
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('support.environment.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('support.environment.leftPanel.table-column1'),
          value: 'analsEnvrnId',
          width: '15%',
        },
        {
          text: this.$i18n.t('support.environment.leftPanel.table-column2'),
          value: 'srvcId',
          width: '15%',
        },
        {
          text: this.$i18n.t('support.environment.leftPanel.table-column3'),
          value: 'srvcNm',
          width: '35%',
        },
        {
          text: this.$i18n.t('support.environment.leftPanel.table-column4'),
          value: 'confmSttus',
          width: '15%',
        },
        {
          text: this.$i18n.t('support.environment.leftPanel.table-column5'),
          sortable: false,
          value: 'conectAdres',
          width: '20%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('support.environment.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'detail',
          headers: [
            {
              text: this.$i18n.t('support.environment.rightPanel.header1'),
              width: '35%',
            },
            {
              text: this.$i18n.t('support.environment.rightPanel.header2'),
              width: '65%',
            },
          ],
          selectedItem: {
            analsEnvrnId: {
              name: this.$i18n.t('support.environment.rightPanel.label1'),
              value: '',
            },
            srvc: {
              name: this.$i18n.t('support.environment.rightPanel.label2'),
              value: '',
              isCustom: true,
            },
            srvcId: {
              name: this.$i18n.t('support.environment.rightPanel.label3'),
              value: '',
              isHidden: true,
            },
            srvcNm: {
              name: this.$i18n.t('support.environment.rightPanel.label4'),
              value: '',
              isHidden: true,
            },
            confmSttus: {
              name: this.$i18n.t('support.environment.rightPanel.label5'),
              value: '',
              isCustom: true,
            },
            analsBegin: {
              name: this.$i18n.t('support.environment.rightPanel.label6'),
              value: '',
            },
            analsEnd: {
              name: this.$i18n.t('support.environment.rightPanel.label7'),
              value: '',
            },
            analsUnt: {
              name: this.$i18n.t('support.environment.rightPanel.label8'),
              value: '',
            },
            analsData: {
              name: this.$i18n.t('support.environment.rightPanel.label9'),
              value: '',
            },
            conectMth: {
              name: this.$i18n.t('support.environment.rightPanel.label10'),
              value: '',
            },
            conectAdres: {
              name: this.$i18n.t('support.environment.rightPanel.label11'),
              value: '',
            },
            creatDt: {
              name: this.$i18n.t('support.environment.rightPanel.label12'),
              value: '',
            },
          },
        },
      ];
    },
    analStart() {
      return this.$i18n.t('support.environment.popUp.label3');
    },
    analEnd() {
      return this.$i18n.t('support.environment.popUp.label4');
    },
    analTool() {
      return this.$i18n.t('support.environment.popUp.label5');
    },
    analData() {
      return this.$i18n.t('support.environment.popUp.label6');
    },
    accessMethod() {
      return this.$i18n.t('support.environment.popUp.label7');
    },
    accessAddress() {
      return this.$i18n.t('support.environment.popUp.label8');
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    btnUpdateStatus() {
      return this.$i18n.t('common.btn.update-status');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.getCommonCode();
      this.tabItems = this.tabItemsComputed;
    },
  },
  created() {
    this.getCommonCode();
    this.getSrvcList();
    this.tabItems = this.tabItemsComputed;
  },
  methods: {
    async getCommonCode() {
      await getCommonCode([COMMON_GROUP_CODE.CODE_CONFM_STTUS])
        .then(({ data }) => {
          this.confmSttusList = data[COMMON_GROUP_CODE.CODE_CONFM_STTUS];
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    async getSrvcList() {
      await getSrvcList()
        .then(({ data }) => {
          const srvcList = data.map(val => {
            return {
              srvcId: val.srvcId,
              srvcNm: val.srvcNm,
            };
          });
          this.srvcList = srvcList;
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    // 분석환경 목록 조회
    async searchEnvmList(searchParams) {
      this.loading = true;
      await getAnalEnvList(searchParams).then(res => {
        const { rows, totalRecords, totalPages } = res.data;
        this.tableItems = rows;
        this.totalRecords = totalRecords;
        this.totalPages = totalPages;
        this.loading = false;
      });
    },
    // row 클릭 시, 상세 조회
    async rowClick(item) {
      this.tabOverlay = true;
      await getAnalEnvDetail(item.analsEnvrnId).then(res => {
        const data = res.data.detail;
        Object.keys(this.tabItems[0].selectedItem).forEach(key => {
          switch (key) {
            case 'creatDt':
              this.tabItems[0].selectedItem[key].value = data[key]
                ? this.formatDate(new Date(data[key]), 'yyyy/MM/dd HH:mm:00')
                : '';
              break;
            default:
              this.tabItems[0].selectedItem[key].value = data[key];
          }
        });
      });
      this.tabOverlay = false;
    },
    // 분석환경 등록
    async insertSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      // 모든 value trim
      Object.keys(this.popup.submitItem).forEach(
        k =>
          (this.popup.submitItem[k] = String(this.popup.submitItem[k]).trim()),
      );

      await insertAnalEnv(this.popup.submitItem).then(({ data }) => {
        if (data.valid) {
          this.$dialogs
            .alert(this.$i18n.t('common.alert.insert-success'))
            .then(() => {
              this.closePopup();
              this.clearTabItems();
              this.reloadToggle = !this.reloadToggle;
            });
        } else {
          this.$dialogs.alert(
            data.message
              ? data.message
              : this.$i18n.t('common.alert.insert-fail'),
          );
        }
      });
    },
    // 분석환경 수정
    async updateSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      // 모든 value trim
      Object.keys(this.popup.submitItem).forEach(
        k =>
          (this.popup.submitItem[k] = String(this.popup.submitItem[k]).trim()),
      );
      const analsEnvrnId = this.tabItems[0].selectedItem.analsEnvrnId.value;
      await updateAnalEnv(analsEnvrnId, this.popup.submitItem).then(
        ({ data }) => {
          if (data.valid) {
            this.$dialogs
              .alert(this.$i18n.t('common.alert.update-success'))
              .then(() => {
                this.closePopup();
                // 표 reload
                this.reloadToggle = !this.reloadToggle;
                // 수정한 row 재선택
                this.rowClick({ analsEnvrnId });
              });
          } else {
            this.$dialogs.alert(
              data.message
                ? data.message
                : this.$i18n.t('common.alert.update-fail'),
            );
          }
        },
      );
    },
    // 분석환경 삭제
    async deleteSubmit() {
      // 선택한 row
      if (!this.tabItems[0].selectedItem.analsEnvrnId.value) {
        const target = this.getPostWord(
          this.$i18n.t('support.environment.target-env'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-delete', { target }),
        );
        return;
      }

      this.$dialogs
        .confirm(
          `<p>${this.$i18n.t(
            'support.environment.target-envId',
          )} : <span class="c-orange">${
            this.tabItems[0].selectedItem.analsEnvrnId.value
          }</span></p>${this.$i18n.t('common.alert.delete-confirm')}`,
        )
        .then(async ({ ok }) => {
          if (ok) {
            await deleteAnalEnv(
              this.tabItems[0].selectedItem.analsEnvrnId.value,
            ).then(({ data }) => {
              if (data.valid) {
                this.$dialogs
                  .alert(this.$i18n.t('common.alert.delete-success'))
                  .then(() => {
                    this.closePopup();
                    //  선택 정보 clear
                    this.clearTabItems();
                    // 표 reload
                    this.reloadToggle = !this.reloadToggle;
                  });
              } else {
                this.$dialogs.alert(
                  data.message
                    ? data.message
                    : this.$i18n.t('common.alert.delete-fail'),
                );
              }
            });
          }
        });
    },
    // 상태 변경
    async updateConfmSttus() {
      if (
        this.popup.submitItem.confmSttus ==
        this.tabItems[0].selectedItem.confmSttus.value
      ) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert.update-fail-sameBefore'),
        );
        return;
      }
      const analsEnvrnId = this.tabItems[0].selectedItem.analsEnvrnId.value;
      const payload = {
        analsEnvrnId,
        confmSttus: this.popup.submitItem.confmSttus,
      };
      await updateAnalConfmSttus(payload).then(({ data }) => {
        if (data.valid) {
          this.$dialogs
            .alert(this.$i18n.t('common.alert.update-success'))
            .then(() => {
              this.closePopup();
              // 표 reload
              this.reloadToggle = !this.reloadToggle;
              // 수정한 row 재선택
              this.rowClick({ analsEnvrnId });
            });
        }
      });
    },
    // 승인상태 명
    getConfmSttusNm(confmSttus) {
      return this.confmSttusList.find(e => e.codeId == confmSttus)?.codeName;
    },
    // 승인상태 색상
    getConfmSttusColor(confmSttus) {
      switch (confmSttus) {
        case '2':
          return '#38A1D1';
        case '3':
          return '#F25555';
        default:
          return '';
      }
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    openPopup(type) {
      this.popup.type = type;
      if (type == 'insert') {
        this.popup.title = this.$i18n.t(
          'support.environment.popUp.title-insert',
        );
        this.popup.submitItem.srvcId = this.srvcList[0].srvcId;
        this.popup.submitItem.confmSttus = this.confmSttusList[0].codeId;
      } else {
        const target = this.getPostWord(
          this.$i18n.t('support.environment.target-env'),
          '을',
          '를',
        );
        let alertMsg = '';
        switch (type) {
          case 'update':
            this.popup.title = this.$i18n.t(
              'support.environment.popUp.title-update',
            );
            alertMsg = this.$i18n.t('common.alert.target-update', { target });
            break;
          case 'confmSttus':
            this.popup.title = this.$i18n.t(
              'support.environment.popUp.title-status',
            );
            alertMsg = this.$i18n.t('common.alert.target-select', { target });
            break;
          default:
            break;
        }

        // 선택한 row
        const selectedItem = this.tabItems[0].selectedItem;
        if (!this.tabItems[0].selectedItem.analsEnvrnId.value) {
          this.$dialogs.alert(alertMsg);
          return;
        }

        Object.keys(this.popup.submitItem).forEach(key => {
          this.popup.submitItem[key] = selectedItem[key]?.value;
        });
      }
      this.popup.show = true;
    },
    closePopup() {
      this.popup.show = false;
      Object.keys(this.popup.submitItem).forEach(key => {
        this.popup.submitItem[key] = '';
      });
    },
    // 우측 패널 데이터 초기화
    clearTabItems() {
      //  선택 정보 clear
      Object.keys(this.tabItems[0].selectedItem).forEach(key => {
        this.tabItems[0].selectedItem[key].value = '';
      });
    },
  },
};
</script>
<style scoped></style>
