import { support } from './index';
import store from '@/store/index';
import axios from 'axios';

/**
 * 사용자 상태 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getUserStatusList(reqObj) {
  return support.post('/transaction/list', reqObj);
}

/**
 * 트랜잭션 이력 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getTransactionHistoryList(reqObj) {
  return support.post('/transaction/transaction', reqObj);
}

/******************************* 예측 모델 관리 *******************************/
/**
 * 예측 모델 목록 조회
 * @param {*} payload
 * @returns
 */
function getPredictionList(payload) {
  return support.post('/prediction/list', payload);
}

/**
 * 예측 모델 상세 조회
 * @param {*} predictModlId
 * @returns
 */
function getPredictionDetail(predictModlId) {
  return support.get(`/prediction/${predictModlId}`);
}

/**
 * 모델 등록
 * @param {*} payload
 * @returns
 */
async function insertModel(payload) {
  const formData = new FormData();

  const keys = Object.keys(payload);
  keys.forEach(key => {
    formData.append(key, payload[key]);
  });

  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    };
    return await axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/support/prediction`,
      formData,
      settingHeaders,
    );
  } catch (error) {
    return error.response; // error msg 출력 위해 return
  }
}

/**
 * 모델 수정
 * @param {*} payload
 * @returns
 */
async function updateModel(predictModlId, payload) {
  const formData = new FormData();
  formData.append('predictModlId', predictModlId);

  const keys = Object.keys(payload);
  keys.forEach(key => {
    formData.append(key, payload[key]);
  });

  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    };
    return await axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/support/prediction/update`,
      formData,
      settingHeaders,
    );
  } catch (error) {
    return error.response; // error msg 출력 위해 return
  }
}

/**
 * 모델 삭제
 * @param {*} predictModlId
 * @returns
 */
async function deleteModel(predictModlId) {
  return support.post(`/prediction/delete`, { predictModlId });
}

/******************************* PHR 분석환경 관리 *******************************/
/**
 * PHR 분석환경 목록 조회
 * @param {*} payload
 * @returns
 */
function getAnalEnvList(payload) {
  return support.post('/environment/list', payload);
}

/**
 * PHR 분석환경 상세 조회
 * @param {*} analsEnvrnId
 * @returns
 */
function getAnalEnvDetail(analsEnvrnId) {
  return support.post('/environment/detail', { analsEnvrnId });
}

/**
 * PHR 분석환경 등록
 * @param {*} payload
 * @returns
 */
function insertAnalEnv(payload) {
  return support.post('/environment', payload);
}

/**
 * PHR 분석환경 수정
 * @param {*} payload
 * @returns
 */
function updateAnalEnv(analsEnvrnId, payload) {
  payload.analsEnvrnId = analsEnvrnId;
  return support.post('/environment/update', payload);
}

/**
 * PHR 분석환경 삭제
 * @param {*} analsEnvrnId
 * @returns
 */
function deleteAnalEnv(analsEnvrnId) {
  return support.post('/environment/delete', { analsEnvrnId });
}

/**
 * PHR 분석환경 상태 변경
 * @param {*} payload
 * @returns
 */
function updateAnalConfmSttus(payload) {
  return support.post('/environment/approve/update', payload);
}

export {
  getUserStatusList,
  getTransactionHistoryList,
  getPredictionList,
  getPredictionDetail,
  insertModel,
  updateModel,
  deleteModel,
  getAnalEnvList,
  getAnalEnvDetail,
  insertAnalEnv,
  updateAnalEnv,
  deleteAnalEnv,
  updateAnalConfmSttus,
};
